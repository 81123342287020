@media print {
    * {
        margin: 0;
        padding: 0;
    }
    .remove-print {
        display: none;
    }
    .remove-print-padding {
        padding: 0px !important;
    }
    .print-font {
        font-size: 8px;
        line-height: 8px;
    }
    .remove-print-margin {
        margin: 0 !important;
    }
}

#dnd {
    display: flex;
    justify-content: space-between;
    min-height: 1200px;
    margin-top: 30px;
}

#numbers {
    position: absolute;
    margin-top: 92px;
    margin-left: -30px;
    text-align: center;
}

#numbers h4 {
    height: 45.3px;
    font-size: 14px;
}

.status {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 3px;
    color: #222;
    background-color: #eee;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.possibleMatch {
  background-color: #0985E6;
}

/* Menu Settings */
.ant-menu-horizontal .ant-menu-item {
  border: none !important;
}

.ant-layout-sider-children, .ant-menu {
  background-color: #09A5E6 !important;
}

.ant-select-dropdown {
  z-index: 9999999 !important;
}

.ant-menu-submenu-title span {
  color: #fff;
}
.ant-menu-sub .ant-menu-item-selected {
  background: #09A5E6 !important;
}


